import '../src/styles/App.css';
import { Static } from './components/Static';
function App() {
  return (
    <div className="App">
      <Static />
    </div>
  );
}

export default App;
