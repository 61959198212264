
export function NavBar() {

	return (
		<nav className='navList'>
			<a href='#home' className='navListItem'>
				Home
			</a>
			<a href='#products' className='navListItem'>
				Products
			</a>
			<a href='#aboutUs' className='navListItem'>
				About us
			</a>
			<a href='#contacts' className='navListItem'>
				Contacts
			</a>
		</nav>
	)
}
