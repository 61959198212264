import phoneIcon from '../assets/imgs/phoneIcon.png'
import emailIcon from '../assets/imgs/emailIcon.png'
import facebookIcon from '../assets/imgs/facebookIcon.png'
import instagramIcon from '../assets/imgs/instagramIcon.png'
import tikTokIcon from '../assets/imgs/tikTokIcon.png'
import locationIcon from '../assets/imgs/locationIcon.png'

export function Contacts() {
	return (
		<div className='comSize' id='contacts'>
			<h2 className='contactH'>Connect With Us</h2>
			<div className='contactIcoCon'>
				<div className='contIcoCon'>
					<img alt='phone icon' src={phoneIcon} className='contactIcon' />
					<a className='conItem' href='tel:+12097794957'>
						+1 (209) 779-4957
					</a>
				</div>
				<div className='contIcoCon'>
					<img alt='email icon' src={emailIcon} className='contactIcon' />
					<a href='mailto:two.sisters.greentea@gmail.com' className='conItem'>
						two.sisters.greentea@gmail.com
					</a>
				</div>
				<div className='contIcoCon'>
					<img alt='facebook icon' src={facebookIcon} className='contactIcon' />
					<a
						className='conItem'
						href='https://www.facebook.com/profile.php?id=61551452092602&mibextid=LQQJ4d'
						target='_blank'
						rel='noreferrer'>
						Two sisters green tea/چای سبز دو خواهر
					</a>
				</div>
				<div className='contIcoCon'>
					<img
						alt='instagram icon'
						src={instagramIcon}
						className='contactIcon'
					/>
					<a
						href='https://www.instagram.com/two_sisters_green_tea?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr'
						className='conItem'
						target='_blank'
						rel='noreferrer'>
						two_sisters_green_tea
					</a>
				</div>
				<div className='contIcoCon'>
					<img alt='tikTok icon' src={tikTokIcon} className='contactIcon' />
					<a
						href='https://www.tiktok.com/@two.sisters.greentea?_t=8iiHK0GZ5mI&_r=1'
						className='conItem'
						target='_blank'
						rel='noreferrer'>
						two.sisters.greentea
					</a>
				</div>
				<div className='contIcoCon'>
					<img alt='location icon' src={locationIcon} className='contactIcon' />
					<a
						href='https://maps.app.goo.gl/h4bTzKmXfcvfCDdw5'
						className='conItem'
						target='_blank'
						rel='noreferrer'>
						1133 W Blaine St, Apt #48 Riverside CA 92507
					</a>
				</div>
			</div>
		</div>
	)
}
