import { Home } from './Home';
import { Products } from './Products';
import { AboutUs } from './AboutUs';
import { Contacts } from './Contacts';
import { NavBar } from './NavBar';

export function Static() {
    
    return (
        <div className="static">
            <NavBar />
            <Home />
            <Products />
            <AboutUs />
            <Contacts />
        </div>
    )
}