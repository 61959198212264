
export function Products() {

    return (
        <div className="comSize" id="products">
            <h2 className="productsH">Our Tea Packing and Delivery</h2>
            <div className="teaCardsContainer">
                We take pride in the quality of our tea, carefully packed in <span className="packWeigh">250g, 500g, 750g</span> plastic jars. For more information about our exquisite tea collection or to make a purchase, feel free to reach out to us. You can contact us through the details provided in the 'Contacts' section of this page. We're here to assist you in finding the perfect tea for your enjoyment.
            </div>
        </div>
    )
}
