
export function AboutUs() {

    return (
			<div className='comSize' id='aboutUs'>
				<h2 className='abUsH'>About us</h2>
				<div className='abUsText'>
					Welcome to Two Sisters Green Tea, where passion and dedication meet to
					create more than just a tea brand—it's a celebration of family,
					tradition, and the exquisite world of green tea. Named after my
					daughters, our commitment to excellence is evident in every carefully
					selected leaf. We offer an experience that tantalizes the senses and
					rejuvenates the spirit, capturing the essence of nature from lush tea
					gardens to your cup. As a family-owned business, our personal touch
					sets us apart, emphasizing authenticity and the value of sharing
					moments around a cup of tea. Whether you're a connoisseur or a casual
					enthusiast, our green teas promise a journey of discovery, unfolding
					stories of tradition, health, and delicate flavors. Join us in
					celebrating the art of tea, the bond of family, and the richness of
					life with Two Sisters Green Tea—where every cup tells a story, and
					every moment becomes a cherished memory.
				</div>
			</div>
		)
}